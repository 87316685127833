import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MaterialModule } from '@app/material.module'
import { SharedModule } from '@app/shared'
import { TranslateModule } from '@ngx-translate/core'
import { ProfileService } from '../services/profile.service'
import { ProfileResetPasswordDialogComponent } from './profile-reset-password-dialog.component'

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, TranslateModule, FlexLayoutModule, MaterialModule, SharedModule],
  declarations: [ProfileResetPasswordDialogComponent],
  providers: [ProfileService],
  entryComponents: [ProfileResetPasswordDialogComponent]
})
export class ProfileResetPasswordDialogModule {}
