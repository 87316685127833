import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { SharedModule } from "@app/shared";
import { TranslateModule } from "@ngx-translate/core";
import { TextEditDialogComponent } from "./text-edit-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  declarations: [TextEditDialogComponent],
  entryComponents: [TextEditDialogComponent],
  exports: [TextEditDialogComponent],
  providers: [
  ],
})
export class TextEditDialogModule {}
