import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '@env/environment'
import { SCCustomInputsDialogConfig, SCCustomInputsDialogService } from '@symblcrowd/ng-symblcrowd'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { CredentialsService } from '../authentication/credentials.service'
import { Logger } from '../logger.service'

const log = new Logger('ErrorHandlerInterceptor')

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private credentialsService: CredentialsService,
    private scCustomInputsDialogService: SCCustomInputsDialogService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(error => {
        return this.parseError(error, request)
      })
    )
  }

  private parseError(response: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> | Promise<HttpEvent<any>> {
    if (response instanceof HttpErrorResponse && response.error instanceof Blob && response.error.type === 'application/json') {
      return this.parseBlobToErrorResponse(response).catch(error => {
        return this.errorHandler(error, request)
      })
    }
    return this.errorHandler(response, request)
  }

  private parseBlobToErrorResponse(error: HttpErrorResponse): Promise<any> {
    console.log(error)
    return new Promise<any>(async (resolve, reject) => {
      console.log(await error.error.text())
      const errorObject = JSON.parse(await error.error.text())
      try {
        reject(
          new HttpErrorResponse({
            error: errorObject,
            headers: error.headers,
            status: error.status,
            statusText: error.statusText,
            url: error.url
          })
        )
      } catch (e) {
        reject(error)
      }
    })
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpErrorResponse, request: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
      log.error('Request error', response)
    }

    switch (response.status) {
      case 401:
        this.credentialsService.setCredentials()
        if (this.router.url.indexOf('/login') == -1) {
          this.router.navigate(['/login'], { queryParams: { redirect: this.router.url }, replaceUrl: true })
        }
        break
      default:
        if (response.error && response.error.message && response.error.error_message) {
          switch (response.error.status) {
            case 200:
              break
            default:
              let config: SCCustomInputsDialogConfig = {
                dialogCssClasses: '',
                actions: [{ index: 'close', label: 'close', type: 'CLOSE' }],
                textConfig: {
                  headerText: response.error.message,
                  bodyText: response.error.error_message
                }
              }
              this.scCustomInputsDialogService.openSCCustomInputsDialog(config)
              break
          }
        }
        break
    }
    throw response
  }
}
