import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/core';

@Component({
  selector: 'text-edit-dialog',
  templateUrl: './transfer-registrations-dialog.component.html'
})
export class TransferRegistrationsDialogComponent extends BaseComponent implements OnInit {
  public isInit = false
  public form: FormGroup
  public selectedRegistrations: string[]
  public transferAll: boolean = false

  public urlConfig = {
    dataUrl: '/exams/exams?filter=transfer,true&per_page=40&order=exam_date_time,asc'
  }

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<TransferRegistrationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super()
  }

  ngOnInit() {
    this.form = this._fb.group({
      transfer_everyone: false,
      selected_registrations: this._fb.control([]),
      exam: this._fb.control({ id: ''}) ,
    })

    if (this.data) {
      if (this.data.selectedRegistrations) {
        this.selectedRegistrations = this.data.selectedRegistrations
        this.form.get('selected_registrations').patchValue(this.selectedRegistrations)
      }
      if (this.data.transferAll) {
        this.transferAll = this.data.transferAll
        this.form.get('transfer_everyone').patchValue(this.transferAll)
      }
    }

    this.isInit = true
  }

  save() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.close(this.form.value)
    }
  }

  close(data?: any) {
    this.dialogRef.close(data)
  }
}

