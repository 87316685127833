import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/core';
import { SCDropzoneComponent, SCDropzoneEvent } from '@symblcrowd/ng-symblcrowd';
import { ID } from './../../../../../../models/core/index';
import { RegistrationsService } from './../../../../registrations/services/registrations.service';


@Component({
  selector: 'send-registration-mail-dialog',
  templateUrl: 'send-registration-mail-dialog.component.html'
})
export class SendRegistrationMailDialogComponent extends BaseComponent implements OnInit {

  @ViewChild('dropzone') scDropZone: SCDropzoneComponent
  
  public isInit: boolean = false
  public form: FormGroup
  public selectedRegistrations: string[]
  public allSelected: boolean
  public examId: ID
  public emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  public file: Blob
  public fileList: FileList

  public formData = new FormData();

  public wysiwygEditorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        {
          list: 'ordered',
        },
        {
          list: 'bullet',
        },
      ],
      [
        {
          script: 'sub',
        },
        {
          script: 'super',
        },
      ],
      [
        {
          size: ['small', false, 'large', 'huge'],
        },
      ], // false = normal
      [
        {
          header: 1,
        },
        {
          header: 2,
        },
      ],
      [{ color: ['#F44336',] }],
      [
        {
          align: ['', 'center', 'right', 'justify'],
        },
      ], // '' = left
      [
        {
          indent: ['-1', '+1'],
        },
      ],
      ['link'],
      ['clean'], // remove formatting button
      ['placeholder'],
      ['templates'],
    ],
    blotFormatter: {},
  }

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<SendRegistrationMailDialogComponent>,
    private registrationsService: RegistrationsService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super()
  }

  ngOnInit() {
    
    this.form = this._fb.group({
      send_at_everyone: false,
      selected_registrations: this._fb.control([]),
      cc: this._fb.control([]),
      subject: '',
      body: ''
    })

    if (this.data) {
      if (this.data.selectedRegistrations) {
        this.selectedRegistrations = this.data.selectedRegistrations
        this.form.get('selected_registrations').patchValue(this.selectedRegistrations)
      }

      if (this.data.allSelected) {
        this.allSelected = this.data.allSelected
        this.form.get('send_at_everyone').patchValue(this.allSelected)
      }

      if (this.data.examId) {
        this.examId = this.data.examId
      }
    }

    this.isInit = true
  }

  send() {
    this.form.markAllAsTouched()
    this.formData.append('data', JSON.stringify(this.form.value))
    if (this.form.valid) {
      this.addSubscription(this.registrationsService.sendRegEmail(this.examId, this.formData)).subscribe(() => {        
        this.close(true)
      })
    }
  }

  openDropzone() {
    this.scDropZone.openFileUpload()
  }

  handleFilesDropped(event: SCDropzoneEvent) {
    if (event.files && event.files.length > 0) {
      this.fileList = event.files
      this.file = event.files.item(0)
      this.formData.append('attachment', this.file);
    }
  }

  close(data?: any) {
    this.dialogRef.close(data)
  }
}

