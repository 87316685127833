import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '@app/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { BaseService } from '@app/core/helperclasses/baseservice';
import { I18nService } from '@app/core/i18n.service';
import { AuthAccount, SystemComponent } from '@app/models';
import { deepCopy, SCData, SCGlobalSearchResult, SCGlobalSearchResultCategory, SCGlobalSearchService, SCShellHeaderService, SCShellService, SCShellSidenavMenu, SCSmartBreadcrumbsService } from '@symblcrowd/ng-symblcrowd';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { AuthenticationService } from './../core/authentication/authentication.service';
import { RequiredPermission } from './../core/authentication/credentials.service';
import { RoutingService } from './../core/routing.service';

export interface SidenavMenu extends SCShellSidenavMenu {
  component?: string
  requiredPermissions?: RequiredPermission[]
  menu?: SidenavMenu[]
}
@Injectable()
export class ShellService extends BaseService {
  public navigationSections: SidenavMenu[] = [
    {
      menu: [
        // {
        //   title: 'menu_dashboard',
        //   routerLink: '/dashboard',
        //   icon: 'dashboard_24',
        //   isActive: false
        // },
        {
          title: 'menu_auditors',
          routerLink: '/auditors',
          icon: 'menu_examiner',
          isActive: false
        },
        {
          title: 'menu_locations',
          routerLink: '/locations',
          icon: 'place_24',
          isActive: false
        },
        {
          title: 'menu_exams',
          routerLink: '/exam-management/exams',
          icon: 'edit_calendar_24',
          isActive: false
        },
        {
          title: 'menu_participants',
          routerLink: '/participants',
          icon: 'menu_participant',
          isActive: false
        },
        {
          title: 'menu_registrations',
          routerLink: '/registrations',
          icon: 'menu_registrations',
          isActive: false
        },
        {
          title: 'menu_texts',
          routerLink: '/texts',
          icon: 'description_24',
          isActive: false
        },
        // {
        //   title: 'menu_system',
        //   routerLink: 'system',
        //   icon: 'settings_24',
        //   isActive: false,
        //   menu: [
            
        //     {
        //       title: 'menu_auth_accounts',
        //       routerLink: '/system/auth/accounts',
        //       requiredPermissions: [
        //         {
        //           module_key: AuthModuleKeys.authbundle,
        //           permission: PermissionTypes.READ
        //         }
        //       ]
        //     },

        //     {
        //       title: 'menu_auth_roles',
        //       routerLink: '/system/auth/roles',
        //       requiredPermissions: [
        //         {
        //           module_key: AuthModuleKeys.authbundle,
        //           permission: PermissionTypes.READ
        //         }
        //       ]
        //       // permission_key: AuthPermissionGroupKeys.SYSTEM_EMAILS
        //     },
        //   ]
        // }
      ]
    }
  ]

  public parsedNavigationSections: SidenavMenu[]

  public globalSearchSub: Subscription


  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private credentialsService: CredentialsService,
    private i18nService: I18nService,
    private scShellService: SCShellService,
    private scShellSCShellHeaderService: SCShellHeaderService,
    private scGlobalSearchService: SCGlobalSearchService,
    private http: HttpService,
    private breadcrumbsService: SCSmartBreadcrumbsService,
    private routingService: RoutingService
  ) {
    super()
    //Edit when Backend Ready
    // this.credentialsService.$credentials.pipe(filter(credentials => !!credentials && !!credentials.id)).subscribe(resp => {
      // this.getMenu(resp)
      this.getMenu(undefined)

      this.scShellService.setConfig({
        logo: './assets/images/AV_Niedersachsen_Logo.svg',
        logoDark: './assets/images/AV_Niedersachsen_Logo_dark.svg',
        enableSearch: true,
        saveAsideToggleState: true,
        headerConfig: {
          headerTopConfig: {
            actions: [
              {
                index: 'file_transfer',
                type: 'FILE'
              },
              {
                index: 'quick_actions',
                type: 'QUICKACTIONS',
                quickActions: [
                  {
                    index: 'test',
                    label: 'test',
                    icon: 'dashboard_24',
                    iconType: 'SVG'
                  }
                ]
              },
              {
                index: 'notifications',
                type: 'NOTIFICATIONS'
              },
              {
                index: 'profile',

                type: 'PROFILE',
                profileActions: [
                  { index: 'profile', label: 'Mein Profil', icon: 'contacts_24', iconType: 'SVG' },
                  // { index: 'settings', label: 'Einstellungen', icon: 'settings_24', iconType: 'SVG' },
                  { index: 'logout', label: 'Abmelden', icon: 'login' }
                ]
              }
            ],
            defaultActionsLimit: 2,
            customTopHeader: false,
            mandant: undefined
          }
        }
      })
    // })

    this.scShellSCShellHeaderService.headerActionClicked.subscribe(resp => {
      console.log('resp', resp)

      switch (resp.action.type) {
        case 'PROFILE':
          switch (resp.button.index) {
            case 'logout':
              this.logout()
              break

            case 'profile':
              this.routingService.openProfileLink(undefined, resp.event)
              break
          }
          break
        case 'QUICKACTIONS':
          switch (resp.button.index) {
            default:
              break
          }
          break
        case 'CUSTOM':
          break
      }
    })

    this.scGlobalSearchService.searchFields = [
      { index: 'exam', label: 'exam', isActive: true },
      { index: 'exam_registration', label: 'exam_registration', isActive: true },
      { index: 'exam_participant', label: 'exam_participant', isActive: true },
      { index: 'exam_location', label: 'exam_location', isActive: true },
      { index: 'exam_auditor', label: 'exam_auditor', isActive: true },
    ];

    this.scGlobalSearchService.globalSearchForm.valueChanges
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(change => {
        this.globalSearch(change);
      });

    this.addSubscription(this.scGlobalSearchService.searchFieldClicked)
      .pipe(
        debounceTime(200),
        distinctUntilChanged()
      )
      .subscribe(resp => {
        this.globalSearch();
      });

    this.scGlobalSearchService.searchResultClicked.pipe(filter(resp => resp != undefined)).subscribe(resp => {
      let path = ``;
      console.log(resp);

      switch (resp.category.data.result_type) {
        case 'EXAM':
          path = `exam-management/exams/${resp.result.data.id}/details/basics`;
          break;
        case 'EXAM_REGISTRATION':
          path = `registrations/${resp.result.data.id}/edit`;
          break;
        case 'EXAM_PARTICIPANT':
          path = `participants/${resp.result.data.id}/edit`;
          break;
        case 'EXAM_LOCATION':
          path = `locations/${resp.result.data.id}/edit`;
          break;
        case 'EXAM_AUDITOR':
          path = `auditors/${resp.result.data.id}/edit`;
          break;
      }

      this.breadcrumbsService.resetBreadcrumbs();

      this.routingService.openUrl([path], { queryParams: undefined }, resp.event);
      this.scGlobalSearchService.searchResultCategories = [];
    });
  }

  setLanguage(language: string) {
    this.i18nService.language = language
  }

  logout() {
    this.addSubscription(this.authenticationService.logout()).subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }))
  }

  getMenu(credentials: AuthAccount) {
    let components: SystemComponent[] = [] //credentials.navigation_components ? credentials.navigation_components : [];
    let menu = deepCopy(<SidenavMenu[]>this.navigationSections)

    this.parseMenu(menu, components)
    this.parsedNavigationSections = menu
    this.scShellService.setNavigationSections(this.parsedNavigationSections)
    //this.scShellService.asideToggle = true
  }

  parseMenu(menu: SidenavMenu[], components: SystemComponent[]) {
    for (let i = menu.length - 1; i >= 0; i--) {
      let section = menu[i]
      if (section.component) {
        let foundComponent = false
        for (let j = components.length - 1; j >= 0 && !foundComponent; j--) {
          let component = components[j]
          if (component.component_name == section.component) {
            foundComponent = true
          }
        }
        if (!foundComponent) {
          menu.splice(i, 1)
        }
      } else if (section.menu) {
        this.parseMenu(section.menu, components)
        if (section.menu.length == 0) {
          menu.splice(i, 1)
        }
      }
    }
  }

  public globalSearch(search?: string) {
    if (this.globalSearchSub) {
      this.globalSearchSub.unsubscribe();
    }
    if (search == undefined) {
      search = this.scGlobalSearchService.globalSearchForm.value;
    }
    let params = new HttpParams();
    params = params.append('search', search);

    for (let field of this.scGlobalSearchService.searchFields) {
      if (field.isActive) {
        params = params.append('search_in', field.index);
      }
    }

    this.globalSearchSub = this.http.get('/search/global', { params: params }).subscribe((resp: SCData<any[]>) => {
      let categories = resp.data.map(data => {
        let category: SCGlobalSearchResultCategory = {
          data: data,
          categoryName: 'search_category_' + data.result_type,
          results: data.search_results.map((dataResult: any) => {
            let result: SCGlobalSearchResult = {
              label: dataResult.result_text,
              data: dataResult
            };
            return result;
          })
        };
        return category;
      });
      this.scGlobalSearchService.searchResultCategories = categories;
    })
  }
}
