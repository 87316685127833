import { Injectable } from '@angular/core'
import { HttpService } from '@app/core/http/http.service'
import { AuthAccount, AuthModule, AuthRole } from '@app/models'
import { SCData } from '@symblcrowd/ng-symblcrowd'
import { Observable } from 'rxjs'

@Injectable()
export class AuthService {
  constructor(private http: HttpService) {}

  getAuthRoles(params?: any) {
    return <Observable<SCData<AuthRole[]>>>this.http.get(`/auth/roles`, { params: params })
  }

  getAuthRole(id: number) {
    return <Observable<SCData<AuthRole>>>this.http.get(`/auth/roles/${id}`)
  }

  generateAuthRole() {
    return <Observable<SCData<AuthRole>>>this.http.get(`/auth/roles/generate`)
  }

  saveAuthRole(model: any) {
    return <Observable<SCData<AuthRole>>>this.http.post(`/auth/roles`, model)
  }

  getAuthModules(params?: any) {
    return <Observable<SCData<AuthModule[]>>>this.http.get(`/auth/modules`, { params: params })
  }

  getAuthAccount(id: number) {
    return <Observable<SCData<AuthAccount[]>>>this.http.get(`/auth/accounts/${id}`)
  }
  saveAuthAccount(model: any) {
    return <Observable<SCData<AuthAccount>>>this.http.post(`/auth/accounts`, model)
  }
}
