<h2 *ngIf="title" mat-dialog-title [innerHtml]="title"></h2>
<div *ngIf="body" mat-dialog-content [innerHtml]="body"></div>
<div mat-dialog-actions *ngIf="actions && actions.length > 0">
  <button
    mat-button
    [color]="action.color"
    *ngFor="let action of actions"
    (click)="handleActionClicked($event, action)"
  >
    {{ action.label | translate }}
  </button>
</div>
