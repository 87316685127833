import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BaseComponent } from '@app/core'
import { SCDataUrlConfig, SCSelectConfig } from '@symblcrowd/ng-symblcrowd'
import { AuthService } from '../../services/auth.service'
@Component({
  selector: 'app-system-account-edit-dialog',
  templateUrl: 'auth-account-edit-dialog.component.html',
  styleUrls: ['auth-account-edit-dialog.component.scss']
})
export class AuthAccountEditDialogComponent extends BaseComponent implements OnInit {
  public form: FormGroup

  public authRolesConfig = {
    config: {
      valueBy: '',
      displayBy: 'role_name',
      compareBy: 'id',
      defaultValue: { id: '' },
      lazyLoading: true
    } as SCSelectConfig,
    urlConfig: {
      dataUrl: '/auth/roles'
    } as SCDataUrlConfig
  }

  constructor(
    public dialogRef: MatDialogRef<AuthAccountEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _fb: FormBuilder,
    private authService: AuthService
  ) {
    super()
    dialogRef.addPanelClass('sc-dialog__cdk-overlay-pane-width-reset')
  }

  ngOnInit(): void {
    this.dialogRef.updateSize('80vw')
    this.form = this._fb.group({
      id: '',
      username: '',
      email: '',
      password: '',
      password_repeat: '',
      is_active: true,
      first_name: '',
      last_name: '',
      is_password_expired: false,
      is_sysadmin: false,
      theme: '',
      user_roles: this._fb.control([])
    })

    if (this.data) {
      if (this.data.systemAccount) {
        this.authService.getAuthAccount(this.data.systemAccount.id).subscribe(resp => {
          this.form.patchValue(resp.data)
        })
      } else {
      }
    }
  }

  close(data?: any): void {
    this.dialogRef.close(data)
  }

  save(): void {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      this.addSubscription(this.authService.saveAuthAccount(this.form.value)).subscribe(resp => {
        this.close(resp.data)
      })
    } else {
      this.formInvalid()
    }
  }
}
