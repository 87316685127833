import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BaseComponent } from '@app/core';
import { Text, TextData } from '@app/models';
import { SCCustomValidators, SCDropzoneComponent, SCDropzoneEvent } from '@symblcrowd/ng-symblcrowd';

const fontSizes = ['8px', '9px', '10px', '11px', '12px', '13px', '14px', '15px', '16px', '17px', '18px', '19px', '20px', '21px', '22px', '23px', '24px', '25px', '26px', '27px', '28px', '29px', '30px', '31px', '32px', '33px', '34px', '35px', '36px', '37px', '38px'];

@Component({
  selector: 'text-edit-dialog',
  templateUrl: 'text-edit-dialog.component.html'
})
export class TextEditDialogComponent extends BaseComponent implements OnInit {

  @ViewChild('dropzone') scDropZone: SCDropzoneComponent

  public isInit = false
  public form: FormGroup
  public text: Text
  public textData: TextData

  public file: Blob
  public fileList: FileList

  public wysiwygEditorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', 'code-block'],
      [
        {
          list: 'ordered',
        },
        {
          list: 'bullet',
        },
      ],
      [
        {
          script: 'sub',
        },
        {
          script: 'super',
        },
      ],
      [
        {
          size: fontSizes,
        },
      ], // false = normal
      [
        {
          header: 1,
        },
        {
          header: 2,
        },
      ],
      [{ color: ['#F44336',] }],
      [
        {
          align: ['', 'center', 'right', 'justify'],
        },
      ], // '' = left
      [
        {
          indent: ['-1', '+1'],
        },
      ],
      ['link'],
      ['clean'], // remove formatting button
      ['placeholder'],
      ['templates'],
    ],
    blotFormatter: {},
  }

  constructor(
    private _fb: FormBuilder,
    public dialogRef: MatDialogRef<TextEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super()
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.text) {
        this.text = this.data.text
      }
      if (this.data.textData) {
        this.textData = this.data.textData
      }
    }

    let form = this._fb.group({
      id: '',
      text_type: this._fb.control({ id: '' }, [Validators.required, SCCustomValidators.idOfObjectIsValid]),
      exam_type: this._fb.control({ id: '' }),
      text_title: ['', [Validators.required]],
      subject: ['', [Validators.required]],
      body_text: ['', [Validators.required]],
    })

    this.form = form

    if (this.text) {
      this.form.patchValue(this.text)
    }

    this.isInit = true
  }

  save() {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      let model = {
        form: this.form.value,
        fileList: this.fileList
      }
      this.close(model)
    }
  }

  close(data?: any) {
    this.dialogRef.close(data)
  }

  openDropzone() {
    this.scDropZone.openFileUpload()
  }

  handleFilesDropped(event: SCDropzoneEvent) {
    if (event.files && event.files.length > 0) {
      this.fileList = event.files
      this.file = event.files.item(0)
    }
  }
}

