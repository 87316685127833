<div *ngIf="isInit" [formGroup]="form">
  <h2 mat-dialog-title>
    <span *ngIf="form.value.id == ''">
      {{ 'text_add' | translate }}
    </span>
    <span *ngIf="form.value.id != ''">
      {{ 'text_edit' | translate }}
    </span>
  </h2>
  <div mat-dialog-content>
    <div class="grid">
      <sc-select
        formControlName="text_type"
        label="text_type"
        [lazyLoading]="false"
        [data]="textData.text_types"
        class="md-col-span-6"
        displayBy="text_type_name"
        [required]="true"
      ></sc-select>
      <sc-input
        formControlName="text_title"
        label="text_title"
        class="md-col-span-6"
        [required]="true"
      ></sc-input>
      <sc-input
        formControlName="subject"
        label="subject"
        class="md-col-span-6"
        [required]="true"
      ></sc-input>
      <ng-container *ngIf="form.value.text_type.text_kind && form.value.text_type.text_kind == 'MAIL'">
        <button
          mat-button
          color="primary"
          class="mat-raised-button-icon-label col-span-6"
          style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
          (click)="openDropzone()"
        >
          <mat-icon>upload</mat-icon>
          <ng-container *ngIf="file">
            {{ 'file_upload_complete' | translate }}
          </ng-container>
          <ng-container *ngIf="!file">
            <ng-container *ngIf="text && text.attachments && text.attachments[0]">
              {{ text.attachments[0].file_name }}
            </ng-container>
            <ng-container *ngIf="!text || !text.attachments || !text.attachments[0]">
              {{ 'no_file' | translate }}
            </ng-container>
          </ng-container>
        </button>
      </ng-container>
      <sc-wysiwyg
        formControlName="body_text"
        [patchDefaults]="false"
        [placeholderData]="textData.placeholders"
        [templateData]="[]"
        [required]="true"
        [config]="wysiwygEditorConfig"
      >
      </sc-wysiwyg>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button color="accent" (click)="close()">
      {{ 'cancel' | translate }}
    </button>
    <button type="button" mat-button color="primary" (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</div>
<sc-dropzone #dropzone (filesDropped)="handleFilesDropped($event)">
</sc-dropzone>
