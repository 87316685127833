import { Component, OnDestroy, OnInit } from '@angular/core'
import { MatIconRegistry } from '@angular/material/icon'
import { DomSanitizer, Title } from '@angular/platform-browser'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import {
  AuthenticationService,
  BaseComponent,
  checkPermissionsFromStorage,
  CredentialsService,
  I18nService,
  Logger
} from '@app/core'
import { environment } from '@env/environment'
import { TranslateService } from '@ngx-translate/core'
import {
  SCCoreService, SCDocumentViewerService,
  SCShellHeaderConfig,
  SCShellHeaderService,
  SCShellService,
  SCTableAction,
  SCTableService,
  SCTableUrlData,
  untilDestroyed
} from '@symblcrowd/ng-symblcrowd'
import { merge } from 'rxjs'
import { filter, map, switchMap } from 'rxjs/operators'
// import { UpdateService } from './core/update.service'

const log = new Logger('App')

const appHeight = () => {
  const doc = document.documentElement
  if (doc) {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`)
  } else {
    doc.style.setProperty('--app-height', `100vh`)
  }
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, OnDestroy {
  public isInit = true //Edit when Backend Ready
  constructor(
    // private updateService: UpdateService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private documentViewerService: SCDocumentViewerService,
    private scShellService: SCShellService,
    private headerService: SCShellHeaderService,
    private scCoreService: SCCoreService,
    private scTableService: SCTableService,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService
  ) {
    super()
    this.headerService.setCheckConfig(this.checkHeaderConfig)
    this.scTableService.setCheckActions(this.checkTableActions)
    this.loadIcons()
    window.addEventListener('resize', appHeight)
  }

  ngOnInit() {
    appHeight()
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode()
    }

    log.debug('init')

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages)

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd))

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute
          while (route.firstChild) {
            route = route.firstChild
          }
          return route
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title))
        }
      })

    this.scCoreService.baseUrl = environment.serverUrl + environment.apiVersion
    this.scCoreService.defaultId = ''

    this.scCoreService.setMapData(data => {
      return { data: data.data, totalCount: data.paging ? data.paging.total_count : data.data.length }
    })

    this.scTableService.showSearchFields = true

    this.scTableService.setMapData(data => {
      return { data: data.data, totalCount: data.paging ? data.paging.total_count : data.data.length }
    })

    this.scTableService.setMapUrlData(resp => {
      let data = resp.data

      let newData: SCTableUrlData = {
        tableActions: data.tableActions,
        tableHeaders: data.tableHeaders,
        tableHeadersDisplayed: data.tableHeadersDisplay
      }

      if (newData.tableActions && newData.tableActions.length > 0) {
        newData.tableHeadersDisplayed.push('actions')
      }
      console.log(data)
      return newData
    })

    this.loadIcons()

    this.addSubscription(this.credentialsService.basicSettings$).subscribe(resp => {
      this.isInit = true
    })
  }

  ngOnDestroy() {
    this.i18nService.destroy()
  }

  checkTableActions(actions: SCTableAction[]) {
    return actions
  }

  checkHeaderConfig(config: SCShellHeaderConfig) {
    for (let i = config.actions.length - 1; i >= 0; i--) {
      let action = config.actions[i]
      if ((<any>action).requiredPermissons && !checkPermissionsFromStorage((<any>action).requiredPermissons)) {
        config.actions.splice(i, 1)
      }
    }

    for (let i = config.splitActions.length - 1; i >= 0; i--) {
      let action = config.splitActions[i]
      if ((<any>action).requiredPermissons && !checkPermissionsFromStorage((<any>action).requiredPermissons)) {
        config.splitActions.splice(i, 1)
      }
    }
    return config
  }

  loadIcons() {
    //Menu Icons
    this.iconRegistry.addSvgIcon(
      'dashboard_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/dashboard-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'settings_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/settings-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'warning_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/warning-24px.svg')
    )

    //Button Icons
    this.iconRegistry.addSvgIcon(
      'save_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/save-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'add_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/add-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'create_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/create-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'description_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/description-24px.svg')
    )

    //Material Icons
    this.iconRegistry.addSvgIcon(
      'print_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/print-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'phone_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/phone-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'email_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/email-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'fax_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/fax-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'link_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/link-24px.svg')
    )
    
    this.iconRegistry.addSvgIcon(
      'download_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/save_alt-24px.svg')
    )
    
    this.iconRegistry.addSvgIcon(
      'delete_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/delete-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'send_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/send-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'edit_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/edit-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'edit_calendar_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/edit_calendar-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'person_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/person-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'place_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/place-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'paid_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/paid-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'notifications_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/notifications-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'cancel_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/cancel-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'event_busy_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/event_busy-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'content_copy_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/content_copy-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'lock_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/lock-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'lock_open_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/lock_open-24.svg')
    )

    this.iconRegistry.addSvgIcon(
      'add_18',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/add-18px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'payed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment_24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'not_payed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/not_payed.svg')
    )

    this.iconRegistry.addSvgIcon(
      'reminded',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment_reminder-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'failed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/final_exam-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'absent_without_excuse',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/absence_unexcused-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'absent_with_excuse',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/absence_excused-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'late_canceled',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/late-canceled-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'canceled',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_canceled-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'canceled_by_participant',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_canceled-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'registered',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_accepted-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'menu_examiner',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/menu_examiner-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'menu_participant',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/menu_participent-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'menu_registrations',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/menu_registrations-24dp.svg')
    )

    this.iconRegistry.addSvgIcon(
      'more_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/more_24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'transfer_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/transfer_24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'credit_card_off_24',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/credit_card_off-24px.svg')
    )

    this.iconRegistry.addSvgIcon(
      'exclude',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/block-24px.svg')
    )

    // Registration Status

    this.iconRegistry.addSvgIcon(
      'status_',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/placeholder.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_payed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_not_payed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/not_payed-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_reminded',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment_reminder-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_failed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/final_exam-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_absent_without_excuse',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/absence_unexcused-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_absent_with_excuse',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/absence_excused-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_late_canceled',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/late-canceled-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_canceled',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_canceled-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_canceled_by_participant',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_canceled-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_registered',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_accepted-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_processing_fee_due',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/registration_accepted-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_processing_fee_payed',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_processing_fee_reminded',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/payment_reminder-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_transferred',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/transfer-status.svg')
    )

    this.iconRegistry.addSvgIcon(
      'status_exclude',
      this.sanitizer.bypassSecurityTrustResourceUrl(window.location.origin + '/assets/material/icons/block-status.svg')
    )
  }
}
