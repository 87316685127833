<ng-container [formGroup]="form">
  <h1 mat-dialog-title>
    <span> {{ 'profile_change_password' | translate }} </span>
  </h1>
  <div mat-dialog-content>
    <div class="grid">
      <sc-input
        class="col-span-12"
        formControlName="current_password"
        label="previous_password"
        type="password"
        [required]="true"
        autocomplete="current-password"
      >
      </sc-input>
      <sc-input
        class="col-span-12"
        formControlName="password"
        label="new_password"
        type="password"
        [required]="true"
        autocomplete="new-password"
      >
      </sc-input>
      <sc-input
        class="col-span-12"
        formControlName="password_repeat"
        label="new_password_repeat"
        type="password"
        [required]="true"
        autocomplete="new-password"
      >
      </sc-input>

      <mat-progress-bar [color]="strengthColor" mode="determinate" [value]="strengthValue">
      </mat-progress-bar>

      <ng-container *ngIf="form.controls.password.value.length > 0">
        <div *ngIf="strengthValue < 30" class="text-danger">
          {{ 'weak_password' | translate }}
        </div>
        <div *ngIf="strengthValue > 30 && strengthValue < 50" class="text-warning">
          {{ 'medium_password' | translate }}
        </div>
        <div *ngIf="strengthValue > 50" class="text-success">
          {{ 'strong_password' | translate }}
        </div>
      </ng-container>

      <mat-error *ngIf="error && form.controls.password_repeat.touched">
        {{ error | translate }}
      </mat-error>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-button color="accent" (click)="close()">
      {{ 'close' | translate }}
    </button>
    <button mat-button color="primary" (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</ng-container>
