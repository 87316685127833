import { OnDestroy, ViewChild } from '@angular/core'
import { SCShellHeaderService, SCTableCardComponent, SCTableComponent } from '@symblcrowd/ng-symblcrowd'

export const TableCardTemplate = `
<sc-card *ngIf='inCard'>
    <sc-card-header *ngIf='title'>
        <sc-card-header-text>
            {{title}}
        </sc-card-header-text>
    </sc-card-header>
    <sc-card-content [content-padded]='false' class='vh-100--shell'>
        <sc-table 
            #scTable
            [urlConfig]='tableUrlConfig' 
            [storageConfig]='tableStorageConfig' 
            [config]='tableConfig' 
            [defaultConfig]='tableDefaultConfig' 
            [data]='tableData' 
            [tableActions]='tableActions' 
            [tableHeaders]='tableHeaders' 
            [tableHeadersDisplayed]='tableHeadersDisplayed' 
            [enableToggleSearchFields]='true'    
            [isLazyLoading]='isLazyLoading'
            (configChanged)='handleConfigChanged($event)'
            (rowClicked)='handleTableRowClicked($event)'
            (actionClicked)='handleTableActionClicked($event)'
            [enableLoadingIndicator]="true"
            [mapFunction]="mapFunction">  
        </sc-table>
    </sc-card-content>
</sc-card>
<sc-table 
    #scTableWithout
    *ngIf='!inCard'
    [urlConfig]='tableUrlConfig' 
    [storageConfig]='tableStorageConfig' 
    [config]='tableConfig' 
    [defaultConfig]='tableDefaultConfig' 
    [data]='tableData' 
    [tableActions]='tableActions' 
    [tableHeaders]='tableHeaders' 
    [tableHeadersDisplayed]='tableHeadersDisplayed' 
    [enableToggleSearchFields]='true'  
    [isLazyLoading]='isLazyLoading'  
    (configChanged)='handleConfigChanged($event)' 
    (rowClicked)='handleTableRowClicked($event)'
    (actionClicked)='handleTableActionClicked($event)'
    [enableLoadingIndicator]="true"
    [mapFunction]="mapFunction">  
</sc-table>`

export class TableCardComponent extends SCTableCardComponent implements OnDestroy {
    @ViewChild('scTableWithout') scTableComponentWithout: SCTableComponent
    @ViewChild('scTable') scTableComponentWith: SCTableComponent

    get scTableComponent() {
        return this.scTableComponentWith || this.scTableComponentWithout
    }

    constructor(public scShellHeaderService: SCShellHeaderService) {
        super(scShellHeaderService)
        this.addHeaderSubscription(this.scShellHeaderService.actionClicked).subscribe(resp => this.handleActionClicked(resp))
    }

    ngOnDestroy() {
        super.ngOnDestroy()
    }

    reloadTableData() {
        if (this.scTableComponent) {
            this.scTableComponent.getUrlTableData()
        }
    }
}
