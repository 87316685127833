<div *ngIf="isInit" [formGroup]="form">
  <h2 mat-dialog-title>
    <span>
      {{ 'send_mails' | translate }}
    </span>
  </h2>
  <div mat-dialog-content>
    <div class="grid">
      <sc-chips-autocomplete
        [label]="'cc'"
        formControlName="cc"
        displayBy="email"
        valueBy="email"
        compareBy=""
        [allowAddingNew]="true"
        [regex]="emailRegex"
        class="ship-email-ff-overlay__chips md-col-span-6"
      >
      </sc-chips-autocomplete>
      <button
        mat-button
        color="primary"
        class="mat-raised-button-icon-label col-span-6"
        (click)="openDropzone()"
      >
        <mat-icon>upload</mat-icon>
        <ng-container *ngIf="file">
          {{ 'file_upload_complete' | translate }}
        </ng-container>
        <ng-container *ngIf="!file">
          {{ 'no_file' | translate }}
        </ng-container>
      </button>
      <sc-input
        formControlName="subject"
        label="subject"
        class="md-col-span-6"
        [required]="true"
      ></sc-input>
      <sc-wysiwyg
        formControlName="body"
        [patchDefaults]="false"
        [placeholderData]="[]"
        [templateData]="[]"
        [required]="true"
        [config]="wysiwygEditorConfig"
      >
      </sc-wysiwyg>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button color="accent" (click)="close()">
      {{ 'cancel' | translate }}
    </button>
    <button type="button" mat-button color="primary" (click)="send()">
      {{ 'send' | translate }}
    </button>
  </div>
</div>
<sc-dropzone #dropzone (filesDropped)="handleFilesDropped($event)">
</sc-dropzone>