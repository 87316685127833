import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from "@app/material.module";
import { SharedModule } from "@app/shared";
import { TranslateModule } from "@ngx-translate/core";
import { TransferRegistrationsDialogComponent } from "./transfer-registrations-dialog.component";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
  ],
  declarations: [TransferRegistrationsDialogComponent],
  entryComponents: [TransferRegistrationsDialogComponent],
  exports: [TransferRegistrationsDialogComponent],
  providers: [
  ],
})
export class TransferRegistrationsDialogModule {}
