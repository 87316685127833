import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { ReactiveFormsModule } from '@angular/forms'
import { MaterialModule } from '@app/material.module'
import { SharedModule } from '@app/shared'
import { SCLocalModule } from '@app/shared/sc/sc.module'
import { TranslateModule } from '@ngx-translate/core'
import { AuthService } from '../../services/auth.service'
import { AuthAccountEditDialogComponent } from './auth-account-edit-dialog.component'

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedModule,
    FlexLayoutModule,
    MaterialModule,
    //Child Modules
    SharedModule,
    SCLocalModule
  ],
  declarations: [AuthAccountEditDialogComponent],
  providers: [
    //Resolvers
    //Services
    AuthService
  ],
  entryComponents: [AuthAccountEditDialogComponent]
})
export class AuthAccountEditDialogModule {}
