import { Component, Inject, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { BaseComponent } from '@app/core'
import { SCCustomInputsDialogService } from '@symblcrowd/ng-symblcrowd'
import { ProfileService } from '../services/profile.service'

@Component({
  selector: 'app-profile-reset-password-dialog',
  templateUrl: 'profile-reset-password-dialog.component.html'
})
export class ProfileResetPasswordDialogComponent extends BaseComponent implements OnInit {
  public type: string
  public types: any[]
  public form: FormGroup
  public error: string

  private strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
  private mediumPassword = new RegExp(
    '((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))'
  )
  public strengthValue = 0
  public strengthColor = ''

  constructor(
    public dialogRef: MatDialogRef<ProfileResetPasswordDialogComponent>,
    private _fb: FormBuilder,
    private profilesService: ProfileService,
    private customDialogService: SCCustomInputsDialogService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super()
  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true
    this.form = this._fb.group({
      id: '',
      current_password: ['', Validators.required],
      password: ['', Validators.required],
      password_repeat: ['', Validators.required]
    })

    if (this.data) {
      if (this.data.userId) {
        this.form.get('id').setValue(this.data.userId)
      }
    }

    this.addSubscription(this.form.get('password').valueChanges).subscribe(password => {
      this.strengthValue = this.checkPasswortStrength(password)
    })
  }

  checkPasswortStrength(password: string) {
    let val = 0
    if (this.strongPassword.test(password)) {
      val = 51 + password.length / 2
      this.strengthColor = 'success'
    } else if (this.mediumPassword.test(password)) {
      ;(val = 50 + password.length / 1), 5
      this.strengthColor = 'warn'
    } else {
      val = 0 + password.length / 3
      this.strengthColor = 'danger'
    }
    return val
  }

  close(data?: any): void {
    this.dialogRef.close(data)
  }

  save(): void {
    this.form.markAllAsTouched()
    if (this.form.valid) {
      if (this.form.value.password == this.form.value.password_repeat) {
        this.addSubscription(this.profilesService.changePassword(this.form.value)).subscribe(
          resp => {
            this.close(resp.data)
          }
        )
      } else {
        this.error = 'password_not_identical'
      }
    }
  }
}
