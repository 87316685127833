import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellRouter } from '@app/shell/shell-router';
//import { SCShellRouter } from '@symblcrowd/ng-symblcrowd/lib/sc-shell'

const routes: Routes = [
  { path: '', redirectTo: '/exam-management/exams', pathMatch: 'full' },
  //{ path: ':clientId', redirectTo: '/:clientId/dashboard', pathMatch: 'full' },
  ShellRouter.childRoutes([
    {
      path: 'dashboard',
      data: {
        breadcrumb: {
          label: 'Dashboard',
          parsedLabel: 'Dashboard',
          url: '/:clientId/dashboard',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
      path: 'exam-management',
      data: {
        breadcrumb: {
          label: 'exam_management',
          parsedLabel: 'exam_management',
          url: '/exam-management/exams',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/exam_management/exams/exams.module').then(m => m.ExamsModule)
    },
    {
      path: 'auditors',
      data: {
        breadcrumb: {
          label: 'auditors',
          parsedLabel: 'auditors',
          url: '/auditors',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/exam_management/auditors/auditors.module').then(m => m.AuditorsModule)
    },
    {
      path: 'locations',
      data: {
        breadcrumb: {
          label: 'locations',
          parsedLabel: 'locations',
          url: '/locations',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/exam_management/locations/locations.module').then(m => m.LocationsModule)
    },
    {
      path: 'participants',
      data: {
        breadcrumb: {
          label: 'participants',
          parsedLabel: 'participants',
          url: '/participants',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/exam_management/participants/participants.module').then(m => m.ParticipantsModule)
    },
    {
      path: 'registrations',
      data: {
        breadcrumb: {
          label: 'registrations',
          parsedLabel: 'registrations',
          url: '/registrations',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/exam_management/registrations/registrations.module').then(m => m.RegistrationsModule)
    },
    {
      path: 'texts',
      data: {
        breadcrumb: {
          label: 'texts',
          parsedLabel: 'texts',
          url: '/texts',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/texts/texts.module').then(m => m.TextsModule)
    },
    {
      path: 'profile',
      data: {
        breadcrumb: {
          label: 'profile',
          parsedLabel: 'profile',
          url: '/profile',
          parsedUrl: '',
          show: true,
          key: 'mainMenuItem'
        }
      },
      loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule)
    },
    {
      path: 'system',
      loadChildren: () => import('./pages/system/system.module').then(m => m.SystemModule)
    },
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '/exam-management/exams', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
