import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { ID } from '@app/models';
import { SCData } from '@symblcrowd/ng-symblcrowd';
import { Observable } from 'rxjs';
import { Exam } from './../../../../models/exam_management/exam_management';
import { ExamParticipant } from './../../../../models/exam_management/participant';
import { ExamRegistration } from './../../../../models/exam_management/registration';

@Injectable()
export class RegistrationsService {
  constructor(private http: HttpService) { }

  sendRegEmail(examId: ID, model: any) {
    return <Observable<SCData<any>>>this.http.post(`/exams/exams/${examId}/participant-mail`, model)
  }

  sendRegInfoEmail(examId: ID, model: any) {
    return <Observable<SCData<any>>>this.http.post(`/exams/exams/${examId}/registration-time-info-mail`, model)
  }

  registrationPayed(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/payed`, {})
  }

  registrationReminder(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/reminder`, {})
  }

  registrationFailed(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/failed`, {})
  }

  registrationAbsentWithoutExcuse(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/absent-without-excuse`, {})
  }

  registrationAbsentWithExcuse(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/absent-with-excuse`, {})
  }

  registrationCancel(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/cancel`, {})
  }

  registrationExclude(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/exclude`, {})
  }

  registrationLateCanceled(registrationId: ID, examId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.patch(`/exams/exams/${examId}/registrations/${registrationId}/late-cancel`, {})
  }

  getRegistrationTemplate(params?: any) {
    return <Observable<SCData<ExamRegistration>>>this.http.get(`/exams/exams-exams/registrations/generate`, {params: params});
  }

  getRegistration(registrationId: ID) {
    return <Observable<SCData<ExamRegistration>>>this.http.get(`/exams/exam-registrations/${registrationId}`);
  }

  deleteRegistration(registrationId: ID) {
    return <Observable<SCData<any>>>this.http.delete(`/exams/exam-registrations/${registrationId}`);
  }

  saveRegistration(examID: ID, model: ExamRegistration, force: boolean = false) {
    let url = `/exams/exams/${examID}/registrations`
    if (force) {
      url += `?force=true`
    }
    return <Observable<SCData<ExamRegistration>>>this.http.post(url, model);
  }

  downloadRegistrationList(examID: ID) {
    return this.http.get(`/exams/exams/${examID}/participants-list`, { observe: 'response', responseType: 'blob' });
  }

  getExams(params?: HttpParams) {
    return <Observable<SCData<Exam[]>>>this.http.get(`/exams/exams?filter=transfer,true&order=exam_date_time,asc`, {params: params});
  }

  getParticipants(params?: HttpParams) {
    return <Observable<SCData<ExamParticipant[]>>>this.http.get(`/exams/exam-participants`, {params: params});
  }

  getStateHistory(examId: ID, registrationId: ID) {
    return <Observable<SCData<any[]>>>this.http.get(`/exams/exams/${examId}/registrations/${registrationId}/status`);
  }

  transferRegistrations(examId: ID, model: any) {
    return <Observable<SCData<any[]>>>this.http.post(`/exams/exams/${examId}/transfer-registrations`, model);
  }

}