import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { SCModule } from '@symblcrowd/ng-symblcrowd'
import { ShellComponent } from './shell.component'
import { ShellService } from './shell.service'

@NgModule({
  imports: [CommonModule, SCModule],
  declarations: [ShellComponent],
  providers: [ShellService]
})
export class ShellModule {}
