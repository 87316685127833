import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from '@app/material.module';
import { SCLocalModule } from './sc/sc.module';

@NgModule({
  imports: [FlexLayoutModule, MaterialModule, CommonModule, SCLocalModule],
  declarations: [],
  exports: [SCLocalModule],
  providers: []
})
export class SharedModule {}
