import { Component, Inject, OnInit } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
export interface AlertDialogAction {
  label: string
  index: string
  color: string
}

export interface AlertDialogConfig {
  allowAutoClose: boolean
}

@Component({
  selector: 'app-alert-dialog',
  templateUrl: 'alert-dialog.component.html'
})
export class AlertDialogComponent implements OnInit {
  public title: string = ''
  public body: string = ''
  public actions: AlertDialogAction[] = []

  public config: AlertDialogConfig = {
    allowAutoClose: false
  }
  constructor(public dialogRef: MatDialogRef<AlertDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    if (this.data) {
      if (this.data.title != undefined) {
        this.title = this.data.title
      }
      if (this.data.body != undefined) {
        this.body = this.data.body
      }
      if (this.data.actions != undefined) {
        this.actions = this.data.actions
      }

      if (this.data.config != undefined) {
        this.config = this.data.config
      }
    }

    this.dialogRef.disableClose = !this.config.allowAutoClose
  }

  close(data?: any): void {
    this.dialogRef.close(data)
  }

  handleActionClicked(event: MouseEvent, action: any): void {
    this.close({ event: event, action: action })
  }
}
