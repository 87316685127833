import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { AuthAccount } from '@app/models'
import { BasicSettings } from '@app/models/general'
import { SCCoreService } from '@symblcrowd/ng-symblcrowd'
import { CookieService } from 'ngx-cookie-service'
import { BehaviorSubject, Observable } from 'rxjs'
import { BaseService } from '../helperclasses'
import { WebsocketService } from '../websocket.service'

export interface RequiredPermission {
  module_key: string
  permission_key?: string
  permission: number
}

const credentialsKey = 'credentials'

export const PermissionTypes = {
  NONE: 0,
  READ: 1,
  EDIT: 2,
  CREATE: 4,
  DELETE: 8
}

export const AuthModuleKeys = {
  search: 'search',
  customfields: 'customfields',
  tableconfig: 'tableconfig',
  websocket: 'websocket',
  translationsbundle: 'translationsbundle',
  authbundle: 'authbundle',
  textsbundle: 'texts',
  exammanagementbundle: 'exam',
}

export const AuthPermissionGroupKeys = {
  TEMPLATE: 'TEMPLATE',
  EXAM: 'EXAM',
  AUDITOR: 'EXAM_AUDITOR',
  LOCATION: 'EXAM_LOCATION',
  PARTICIPANT: 'EXAM_PARTICIPANT',
  REGISTRATION: 'EXAM_REGISTRATION',
  TEXT: 'TEXT_PLACEHOLDER'
}

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService extends BaseService {
  private _credentials: AuthAccount = null
  private _credentials$ = new BehaviorSubject<AuthAccount>(this._credentials)

  private _settings: any
  private _settings$ = new BehaviorSubject(undefined)
  private _basicSettings: BasicSettings
  private _basicSettings$ = new BehaviorSubject<BasicSettings>(undefined)

  constructor(private cookieService: CookieService, private scCoreService: SCCoreService, private websocketService: WebsocketService) {
    super()
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey)
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials)
      this._credentials$.next(this._credentials)
      let headers = new HttpHeaders()
      headers = headers.append('Authorization', 'Bearer ' + this.token).append('X-FederalStateId', 'ga8AD1dOWz')
      this.scCoreService.setHttpHeaders(headers)
      this.websocketService.openGlobalWebsocket(this.token)
    } else {
      let token = this.cookieService.get('token')
      if (token) {
        //TODO hier user für token laden
        this._credentials = <any>{}
        this._credentials.session_token = token
      }
    }

    this.addSubscription(this._settings$, { filterUndefined: false }).subscribe(change => {
      this._settings = change
    })
    this.addSubscription(this._basicSettings$, { filterUndefined: false }).subscribe(change => {
      this._basicSettings = change
    })
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): AuthAccount {
    return this._credentials
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get $credentials(): Observable<AuthAccount> {
    return this._credentials$.asObservable()
  }

  /**
   * Gets the token from the user credentials.
   * @return The token or '' if the user is not authenticated.
   */
  get token(): string {
    return this._credentials ? this._credentials.session_token : ''
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get remember(): boolean {
    return localStorage.getItem(credentialsKey) != undefined
  }

  get settings() {
    return this._settings
  }
  get basicSettings() {
    return this._basicSettings
  }

  get settings$() {
    return this._settings$.asObservable()
  }
  get basicSettings$(): Observable<BasicSettings> {
    return this._basicSettings$.asObservable()
  }

  set settings(value: any) {
    this._settings$.next(value)
  }

  set basicSettings(value: BasicSettings) {
    this._basicSettings$.next(value)
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: AuthAccount, remember?: boolean) {
    this._credentials = credentials || null

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage
      storage.setItem(credentialsKey, JSON.stringify(credentials))
      let headers = new HttpHeaders()
      headers = headers.append('Authorization', 'Bearer ' + this.token).append('X-FederalStateId', 'ga8AD1dOWz')
      this.scCoreService.setHttpHeaders(headers)
      this.websocketService.openGlobalWebsocket(this.token)
      if (remember) {
        this.cookieService.set(
          'token',
          this._credentials.session_token,
          0,
          '',
          window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)
        )
      }
    } else {
      this.cookieService.deleteAll('', window.location.hostname.substring(window.location.hostname.indexOf('.') + 1))
      sessionStorage.removeItem(credentialsKey)
      localStorage.removeItem(credentialsKey)
      this.scCoreService.setHttpHeaders(undefined)
      this.websocketService.closeGlobalWebsocket()
    }
    this._credentials$.next(this._credentials)
  }

  checkPermissions(requiredPermissions: RequiredPermission[]) {
    return checkPermissions(requiredPermissions, this._credentials)
  }
}

export function checkPermissions(requiredPermissions: RequiredPermission[], user: AuthAccount) {
  if (!requiredPermissions || requiredPermissions.length == 0 || user.is_sysadmin) {
    return true
  }
  if (user && user.id && user.user_roles) {
    for (let requiredPermission of requiredPermissions) {
      let permissionMatched = false
      for (let role of user.user_roles) {
        if (role.modules) {
          for (let module of role.modules) {
            if (module.module.module_key == requiredPermission.module_key) {
              if (checkPermissionValue(module.permission, requiredPermission.permission)) {
                permissionMatched = true
              } else if (requiredPermission.permission_key) {
                for (let permissionGroup of module.permission_groups) {
                  if (
                    permissionGroup.permission_group.permission_key == requiredPermission.permission_key &&
                    checkPermissionValue(permissionGroup.permission, requiredPermission.permission)
                  ) {
                    permissionMatched = true
                  } else if (permissionGroup.sub_permission_groups) {
                    for (let subPermissionGroup of permissionGroup.sub_permission_groups) {
                      if (subPermissionGroup.permission_group.permission_key == requiredPermission.permission_key) {
                        if (
                          checkPermissionValue(permissionGroup.permission, requiredPermission.permission) ||
                          checkPermissionValue(subPermissionGroup.permission, requiredPermission.permission)
                        ) {
                          permissionMatched = true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      if (!permissionMatched) {
        return false
      }
    }
  }
  return true
}

export function checkPermissionsFromStorage(requiredPermissions: RequiredPermission[]) {
  let user = localStorage.getItem('credentials') ? JSON.parse(localStorage.getItem('credentials')) : JSON.parse(sessionStorage.getItem('credentials'))
  return checkPermissions(requiredPermissions, user)
}

export function checkPermissionValue(currentPermission: number, permission: number) {
  let currentPermissionBin = currentPermission.toString(2)
  let permissionBin = permission.toString(2)
  return currentPermissionBin.charAt(currentPermissionBin.length - permissionBin.length) == permissionBin.charAt(0)
}
