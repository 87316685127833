import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { AlertDialogAction, AlertDialogComponent, AlertDialogConfig } from '@app/dialogs/alert-dialog/alert-dialog.component';
import { Auditor, Exam, ExamLocation, ExamParticipant, Text, TextData } from '@app/models';
import { TransferRegistrationsDialogComponent } from '@app/pages/exam_management/exams/exam-details/exam-details-registrations/transfer-registrations-dialog/transfer-registrations-dialog.component';
import { SCSmartBreadcrumbsService } from '@symblcrowd/ng-symblcrowd';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { StateHistoryDialogComponent } from './../dialogs/state-history-dialog/state-history-dialog.component';
import { AuthRole } from './../models/auth/auth';
import { ID } from './../models/core/index';
import { ExamRegistration } from './../models/exam_management/registration';
import { SendRegistrationMailDialogComponent } from './../pages/exam_management/exams/exam-details/exam-details-registrations/send-registration-mail-dialog/send-registration-mail-dialog.component';
import { TextEditDialogComponent } from './../pages/texts/text-edit-dialog/text-edit-dialog.component';
import { BaseService } from './helperclasses/baseservice';

export interface OpenUrlConfig {
  mouseEvent?: MouseEvent
  openInNewTab?: boolean
  resetBreadCrumbs?: boolean
  queryParams?: any
}
@Injectable({
  providedIn: 'root'
})
export class RoutingService extends BaseService {
  private redirectUrl: string
  private redirectUrlIsNew = false
  constructor(
    private dialogService: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private smartBreadcrumbsService: SCSmartBreadcrumbsService
  ) {
    super()
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(event => {
      if (!router.getCurrentNavigation().extras.state || !this.router.getCurrentNavigation().extras.state.keepRedirectUrl) {
        this.resetRedirectUrl()
      }
      this.redirectUrlIsNew = false
      //this.resetRedirectUrl()
    })
  }

  getCurrentRoute() {
    return this.router.routerState.snapshot.url
  }

  setRedirectUrl(redirectUrl?: string) {
    if (!redirectUrl) {
      redirectUrl = this.getCurrentRoute()
    }
    this.redirectUrl = redirectUrl
    this.redirectUrlIsNew = true
  }

  renewRedirectUrl() {
    this.redirectUrlIsNew = true
  }

  resetRedirectUrl() {
    this.redirectUrl = undefined
    this.redirectUrlIsNew = false
  }

  public openUrl(url: any[], navigationExtras?: NavigationExtras, mouseEvent?: any, resetBreadCrumbs?: boolean, openInNewTab?: boolean) {
    if (openInNewTab || (mouseEvent && (mouseEvent.ctrlKey || mouseEvent.metaKey))) {
      let urlTree = this.router.createUrlTree(url, navigationExtras)
      if (mouseEvent.shiftKey) {
        console.log('SHIFT KEY PRESSED')
        window.open(urlTree.toString(), '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes,modal=yes')
      } else {
        window.open(urlTree.toString())
      }
    } else {
      if (this.redirectUrl && !this.redirectUrlIsNew) {
        url = [this.redirectUrl]
        this.resetRedirectUrl()
      }
      if (resetBreadCrumbs) {
        this.smartBreadcrumbsService.resetBreadcrumbs()
      }
      this.router.navigate(url, { ...navigationExtras, state: { ...navigationExtras.state, keepRedirectUrl: this.redirectUrlIsNew } }) //navigationExtras) //
    }
  }

  navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras)
  }

  public openAlertDialog(title: string, body: string, actions: AlertDialogAction[], config?: AlertDialogConfig) {
    return this.dialogService
      .open(AlertDialogComponent, {
        data: {
          title: title,
          body: body,
          actions: actions,
          config: config
        }
      })
      .afterClosed()
  }
  
  public openExamOverview(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['exam-management', 'exams']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openExamCreateLink(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['exam-management', 'exams', 'create']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openExamEditLink(exam: Exam, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['exam-management', 'exams', exam.id, 'edit']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openExamDetailsLink(exam: Exam, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['exam-management', 'exams', exam.id, 'details']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openParticipantOverview(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['participants']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openParticipantCreateLink(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['participants', 'create']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openParticipantEditLink(participant: ExamParticipant, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['participants', participant.id, 'edit']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openParticipantDetailsLink(participant: ExamParticipant, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['participants', participant.id, 'details']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openAuditorCreateLink(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['auditors', 'create']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openAuditorEditLink(auditor: Auditor, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['auditors', auditor.id, 'edit']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openAuditorOverview(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['auditors']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openLocationCreateLink(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['locations', 'create']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openLocationEditLink(location: ExamLocation, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['locations', location.id, 'edit']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openLocationOverview(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['locations']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openRegistrationCreateLink(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['registrations', 'create']
    console.log(queryParams)
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openRegistrationEditLink(registration: ExamRegistration, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['registrations', registration.id, 'edit']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openRegistrationOverview(queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['registrations']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openExamRegistrationOverview(examId: string, queryParams: any, mouseEvent: MouseEvent) {
    let urlTree = ['exam-management', 'exams', examId, 'details', 'registrations']
    this.openUrl(urlTree, { queryParams: queryParams }, mouseEvent)
  }

  public openAuthRoleEditLink(authRole: AuthRole, mouseEvent?: MouseEvent) {
    this.openUrl(['system', 'auth', 'roles', authRole.id, 'edit'], {}, mouseEvent)
  }
  public openAuthRoleCreateLink(mouseEvent?: MouseEvent) {
    this.openUrl(['system', 'auth', 'roles', 'create'], {}, mouseEvent)
  }

  // profile
  public openProfileLink(queryParams?: any, mouseEvent?: MouseEvent) {
    this.openUrl(['profile'], { queryParams: queryParams }, mouseEvent)
  }

  public openTextEditDialog(textData: TextData, text?: Text) {
    return this.dialogService
      .open(TextEditDialogComponent, {
        data: { text: text, textData: textData },
        maxWidth: '80vw',
        width: '1000px'
      })
      .afterClosed() as Observable<Text>
  }

  public openTransferRegistrationsDialog(selectedRegistrations: string[], transferAll: boolean) {
    return this.dialogService
      .open(TransferRegistrationsDialogComponent, {
        data: { selectedRegistrations: selectedRegistrations, transferAll: transferAll },
        maxWidth: '80vw',
        width: '600px'
      })
      .afterClosed() as Observable<Text>
  }

  public openSendRegistrationMailsDialog(selectedRegistrations: string[], allSelected: boolean, examId: ID) {
    return this.dialogService
      .open(SendRegistrationMailDialogComponent, {
        data: { selectedRegistrations: selectedRegistrations, allSelected: allSelected, examId: examId },
        maxWidth: '80vw',
        width: '1000px'
      })
      .afterClosed() as Observable<Text>
  }

  public openStateHistoryDialog(statusHistory: any) {
    return this.dialogService
      .open(StateHistoryDialogComponent, {
        width: '500px',
        maxWidth: '90vw',
        data: { history: statusHistory }
      })
      .afterClosed() as Observable<any>
  }
}
