import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'app-state-history-dialog',
  templateUrl: 'state-history-dialog.component.html'
})
export class StateHistoryDialogComponent {
  public history: any[]

  constructor(public dialogRef: MatDialogRef<StateHistoryDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    if (this.data) {
      if (this.data.history) {
        this.history = this.data.history
        console.log(this.history)
      }
    }
  }

  close(): void {
    this.dialogRef.close()
  }
}
