<div *ngIf="isInit" [formGroup]="form">
  <h2 mat-dialog-title>
    <span>
      {{ 'transfer_registrations' | translate }}
    </span>
  </h2>
  <div mat-dialog-content>
    <div class="grid">
      <div class="grid col-span-12">
        <sc-select
          formControlName="exam"
          label="exam_date"
          [lazyLoading]="true"
          [urlConfig]="urlConfig"
          class="md-col-span-6"
          displayBy="display_value"
          [required]="true"
        ></sc-select>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <button type="button" mat-button color="accent" (click)="close()">
      {{ 'cancel' | translate }}
    </button>
    <button type="button" mat-button color="primary" (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</div>
