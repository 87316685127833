import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { AuthAccount } from '@app/models';
import { SCData } from '@symblcrowd/ng-symblcrowd';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileService {
  constructor(private http: HttpService) { }

  getProfile() {
    return <Observable<SCData<AuthAccount>>>this.http.get(`/auth/me`);
  }

  changePassword(model: AuthAccount) {
    return <Observable<SCData<AuthAccount>>>this.http.post(`/auth/me/change-password`, model)
  }

  saveProfile(model: AuthAccount) {
    return <Observable<SCData<AuthAccount>>>this.http.post(`/auth/me`, model);
  }

}
