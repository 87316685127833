import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { SendRegistrationMailDialogModule } from '@app/pages/exam_management/exams/exam-details/exam-details-registrations/send-registration-mail-dialog/send-registration-mail-dialog.module';
import { TransferRegistrationsDialogModule } from '@app/pages/exam_management/exams/exam-details/exam-details-registrations/transfer-registrations-dialog/transfer-registrations-dialog.module';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from './../material.module';
import { ProfileResetPasswordDialogModule } from './../pages/profile/profile-reset-password-dialog/profile-reset-password-dialog.module';
import { AuthAccountEditDialogModule } from './../pages/system/auth/auth-accounts/auth-account-edit-dialog/auth-account-edit-dialog.module';
import { AuthRoleEditModule } from './../pages/system/auth/auth-roles/auth-role-edit/auth-role-edit.module';
import { TextEditDialogModule } from './../pages/texts/text-edit-dialog/text-edit-dialog.module';
import { AlertDialogComponent } from './alert-dialog/alert-dialog.component';
import { StateHistoryDialogComponent } from './state-history-dialog/state-history-dialog.component';

@NgModule({
  imports: [SharedModule, MatDialogModule, ReactiveFormsModule, MaterialModule, TranslateModule, CommonModule, TextEditDialogModule, SendRegistrationMailDialogModule, TransferRegistrationsDialogModule, ProfileResetPasswordDialogModule, AuthRoleEditModule, AuthAccountEditDialogModule],
  declarations: [AlertDialogComponent, StateHistoryDialogComponent],
  entryComponents: [AlertDialogComponent, StateHistoryDialogComponent],
  providers: []
})
export class DialogsModule { }
