<h2 mat-dialog-title>
  {{ 'state_history_dialog_title' | translate }}
</h2>
<div mat-dialog-content>
  <div>
    <ul class="timeline-list">
      <li
        class="timeline-list__item"
        *ngFor="let stat of history; let l = last"
        [ngClass]="{ 'mb-0': l }"
      >
        <div class="timeline-list__label-wrapper">
          <div class="timeline-list__label mat-subheading-1 mb-0 text-right">
            {{ stat.status_def.status_name }}
          </div>
        </div>

        <div class="timeline-list__icon-wrapper">
          <div class="timeline-list__connector"></div>
          <div class="timeline-list__icon-bg">
            <mat-icon [svgIcon]="'status_' + stat.status_def.status_def_type"></mat-icon>
          </div>
        </div>

        <div class="timeline-list__text">
          <span *ngIf="stat.status_date_time">
            {{ stat.status_date_time | date: 'dd.MM.y HH:mm' }}</span
          >
          <span class="mat-caption text-muted">
            <ng-container
              *ngIf="
                stat.created_by && (stat.created_by.first_name || stat.created_by.last_name)
              "
            >
              {{ stat.created_by.first_name }} {{ stat.created_by.last_name }}
            </ng-container>
            <ng-container
              *ngIf="
                stat.created_by && (!stat.created_by.first_name || !stat.created_by.last_name)
              "
            >
              {{ stat.created_by.username }}
            </ng-container>
          </span>
        </div>
      </li>
    </ul>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-button color="warn" (click)="close()">
    {{ 'cancel' | translate }}
  </button>
</div>
