<ng-container [formGroup]="form">
  <h2 mat-dialog-title>
    <span *ngIf="!form.value.id">
      {{ 'create_auth_account' | translate }}
    </span>
    <span *ngIf="form.value.id"> {{ 'auth_account_edit' | translate }}</span>
  </h2>
  <div
    mat-dialog-conten
    class="sc-dialog__content sc-dialog__content--w-md sc-dialog__content--sys-role-height inner-layout__wrapper fr-cols fr-cols--1-1"
  >
    <div class="inner-layout__left">
      <div class="grid p-4">
        <sc-input class="col-span-6" formControlName="first_name" [label]="'first_name'">
        </sc-input>
        <sc-input class="col-span-6" formControlName="last_name" [label]="'last_name'">
        </sc-input>
        <sc-input
          class="col-span-12"
          formControlName="username"
          [required]="true"
          [label]="'username'"
        >
        </sc-input>
        <sc-input type="email" class="col-span-12" formControlName="email" [label]="'email'">
        </sc-input>
        <sc-input
          type="password"
          class="col-span-6"
          formControlName="password"
          [label]="'password'"
        >
        </sc-input>
        <sc-input
          type="password"
          class="col-span-6"
          formControlName="password_repeat"
          [label]="'password_repeat'"
        >
        </sc-input>
        <mat-error
          *ngIf="
            form.controls.password.value &&
            form.controls.password_repeat.value &&
            form.controls.password.value != form.controls.password_repeat.value
          "
          >{{ 'password_not_equal' | translate }}</mat-error
        >

        <sc-input
          type="checkbox"
          class="col-span-12"
          formControlName="is_sysadmin"
          [label]="'is_sysadmin'"
        >
        </sc-input>
      </div>
    </div>

    <div class="inner-layout__right ">
      <div class="grid p-4">
        <sc-select
          class="col-span-12"
          label="user_roles"
          formControlName="user_roles"
          [multiple]="true"
          [displayBy]="authRolesConfig.config.displayBy"
          [compareBy]="authRolesConfig.config.compareBy"
          [valueBy]="authRolesConfig.config.valueBy"
          [urlConfig]="authRolesConfig.urlConfig"
          [lazyLoading]="authRolesConfig.config.lazyLoading"
        >
        </sc-select>
      </div>
      <!--
      <div class="repeater repeater--action-right repeater--p-4">
        <div class="repeater__content">
          <div formArrayName="user_roles">
            <div
              *ngFor="let roleControl of rolesControl.controls; let i = index; let last = last"
              [formGroupName]="i"
              class="sys-role__item border-divider"
            >
              <div class="sys-role__item-header">
                <sc-autocomplete
                  formControlName="role"
                  [data]="roles"
                  displayBy="role_name"
                  [label]="'auth_account_role' | translate"
                >
                </sc-autocomplete>
                <button mat-icon-button color="primary" class="ml-3" (click)="removeRole(i)">
                  <mat-icon>delete</mat-icon>
                </button>
              </div>
              <div class="checkbox-group checkbox-group--horizontal">
                <div class="checkbox-group__button" *ngFor="let mandant of mandanten">
                  <mat-checkbox
                    (change)="toggleMandant(i, mandant)"
                    [checked]="hasMandant(i, mandant)"
                    [indeterminate]="hasMandant(i, mandant) && !hasAllMandantFillialen(i, mandant)"
                    [color]="'primary'"
                  >
                    {{ mandant.name }}
                  </mat-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="repeater__action">
          <button role="button" mat-raised-button color="primary" class="mat-button-icon-label" (click)="addRole()">
            <mat-icon> add </mat-icon>
            {{ 'auth_account_role_add' | translate }}
          </button>
        </div>
      </div>-->
    </div>
  </div>

  <div mat-dialog-actions>
    <button type="button" mat-button color="accent" (click)="close()">
      {{ 'cancel' | translate }}
    </button>
    <button type="button" mat-button color="primary" (click)="save()">
      {{ 'save' | translate }}
    </button>
  </div>
</ng-container>
