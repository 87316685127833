import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CredentialsService } from '@app/core/authentication/credentials.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';


/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor(private credentialsService: CredentialsService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!/^(http|https):/i.test(request.url)) {
      if (/api\/v[0-9].*/.test(request.url)) {
        request = request.clone({
          url: `${environment.serverUrl}${request.url}`
        })
      } else {
        request = request.clone({
          url: `${environment.serverUrl}${environment.apiVersion}${request.url}`
        })
      }
      if (this.credentialsService.token) {
        request = request.clone({
          headers: request.headers.append('Authorization', 'Bearer ' + this.credentialsService.token).append('X-FederalStateId', 'ga8AD1dOWz')
        })
      }
    }
    return next.handle(request)
  }
}
