import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';


const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated()) {
      return this.checkRoutePermissions(route);
    }
    console.log('Not Authorized');
    return false;
  }

  checkRoutePermissions(route: ActivatedRouteSnapshot) {
    let requiredPermissions = route.data.requiredPermissions;
    let user = this.credentialsService.credentials;
    if (!requiredPermissions || requiredPermissions.length == 0 || user.is_sysadmin) {
      return true;
    }
    return this.credentialsService.checkPermissions(requiredPermissions);
  }

  hasPermission(currentPermission: number, permission: number) {
    let currentPermissionBin = currentPermission.toString(2);
    let permissionBin = permission.toString(2);
    return currentPermissionBin.charAt(currentPermissionBin.length - permissionBin.length) == permissionBin.charAt(0);
  }
}
