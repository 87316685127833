import { Injectable, OnDestroy } from '@angular/core';
import { AppInjector } from '@app/app.module';
import { SCBaseComponent, SCToastAction, SCToastConfig, SCToastService } from '@symblcrowd/ng-symblcrowd';


@Injectable()
export class BaseComponent extends SCBaseComponent implements OnDestroy {
  private toastService: SCToastService = AppInjector.get(SCToastService);

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  formInvalid(actions?: SCToastAction[]) {
    let toastConfig = new SCToastConfig();
    toastConfig.color = 'danger';
    toastConfig.duration = 2000;
    return this.openToast('invalid_form', toastConfig, actions);
    //this.toastService.open(this.translateService.instant('invalid_form'), undefined, toastConfig)
  }

  openToast(message: string, config: SCToastConfig, actions?: SCToastAction[]) {
    return this.toastService.open(message, actions, config);
  }
}
